html {
  height: 100vh;
  margin: 0;
  padding: 0;
  font-size: 100%;
  font-variant-ligatures: no-common-ligatures;
  text-rendering: geometricPrecision;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
body {
  background-color: transparent;
  font-size: 1rem;
  line-height: normal;
  letter-spacing: -0.3px;
}
body .frame-root,
body .frame-root .frame-content {
  height: 100%;
}
p,
h1,
h2,
h3,
h4,
h5,
h6,
label,
a,
dl,
ol,
ul {
  margin-bottom: 0;
}
b,
strong {
  font-weight: 700;
}
a,
a:focus,
a:hover {
  color: inherit;
}
a:focus,
a:hover {
  cursor: pointer;
}
button {
  margin: 0;
  padding: 0;
  background-color: transparent;
  border: none;
  cursor: pointer;
}
button:disabled {
  cursor: initial;
}
button:focus {
  outline: 0;
}
button:focus-visible {
  box-shadow: 0 0 0 2px #5297e2;
}
img {
  image-orientation: from-image;
}
@supports (-webkit-overflow-scrolling: touch) {
  input,
  textarea {
    font-size: 16px;
  }
}
input {
  padding: 0;
}
input::-ms-clear {
  display: none;
}
input:focus,
textarea:focus {
  outline: none;
}
ul,
ol {
  padding: 0;
  list-style: none;
}
.truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
